import React, { useState, useEffect } from 'react';
import Script from 'next/script';

export default () => {
  const cacheKey = 'app/debug/isEruda';
  const [isEruda, setIsEruda] = useState(
    process.env.NODE_ENV === 'development'
    || (
      typeof window !== 'undefined' 
      && localStorage.getItem(cacheKey) === 'true'
    )
    || true
  );

  const setDebugMode = () => {
    setIsEruda(true);

    if (typeof window !== 'undefined') {
      localStorage.setItem(cacheKey, true);
    }
  };

  useEffect(() => {
    // 连续点击10次页面(间隔不小于200ms), 进入调试模式, 用于调试
    let clickCount = 0;
    let timerReset = null;

    const clickHandler = () => {
      clearTimeout(timerReset);

      clickCount += 1;
      if (clickCount >= 10) {
        setDebugMode();
      }

      timerReset = setTimeout(() => {
        clickCount = 0;
      }, 200);
    };

    document.addEventListener('click', clickHandler);

    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('click', clickHandler);
      }
    };
  }, []);

  return isEruda 
    ? (
      <Script
        src="//cdn.jsdelivr.net/npm/eruda"
        onLoad={() => {
          window.eruda.init();
        }}
      />
    ) 
    : null;
};
