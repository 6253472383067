import logo from '@/assets/logo.png';

export const SiteInfoConfig = {
  name: '医讯',
  logo,
  description: '医讯医讯医讯医讯医讯',
};

/**
 * 缓存键值对
 */
export const EnumCacheKeyMap = {
  'app/token': 'app/token',
  'app/debug/isEruda': 'app/debug/isEruda',
  'app/message/lastReadId': 'app/message/lastReadId'
};

export const EnumThemeType = {
  0: {
    baseColor: '#009182', // 基础颜色
    liveBookColor: '#065A51', // 预约按钮颜色
    liveBannerColor: '#8FC8C2', // 倒计时块背景色
    liveBubbleColor: '#E3EDEC',
  },
  1: {
    baseColor: '#1B92D5', // 基础颜色
    liveBookColor: '#163B92', // 预约按钮颜色
    liveBannerColor: '#78B9DE', // 倒计时块背景色
    liveBubbleColor: '#DFECFA'
  },
  2: {
    baseColor: '#C52A12', // 基础颜色
    liveBookColor: '#AC0000', // 预约按钮颜色
    liveBannerColor: '#FAE7DB', // 倒计时块背景色
    liveBubbleColor: '#FBEBE1'
  },
  3: {
    baseColor: '#EF7900', // 基础颜色
    liveBookColor: '#E64700', // 预约按钮颜色
    liveBannerColor: '#FFD6AD', // 倒计时块背景色
    liveBubbleColor: '#FFF1DC'
  },
  4: {
    baseColor: '#B464F4', // 基础颜色
    liveBookColor: '#4909B9', // 预约按钮颜色
    liveBannerColor: '#B787E5', // 倒计时块背景色
    liveBubbleColor: '#F3E4FF'
  }
};
