import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { Modal } from 'antd-mobile';
import 'xgplayer/dist/index.min.css';
import 'xgplayer/es/plugins/danmu/index.css';
import { SiteInfoConfig } from '@/constants';
import { isApp } from '@/utils/env';
import Eruda from '@/components/eruda';
import '../theme/xgplayer/skin/style/index.scss';
import '../styles/globals.css';

// eslint-disable-next-line max-lines-per-function
function MyApp({ Component, pageProps }) {
  const AppProvider = dynamic(import('@/pages/store.tsx'), { ssr: false });

  const router = useRouter();
  useEffect(() => {
    const onRouteChangeStart = () => {
      Modal.clear();
    };

    const onRouteChangeComplete = () => {
      if (!window.customLocationHistory) {
        window.customLocationHistory = {
          length: 1,
        };
      } else {
        if (!window.customLocationHistory.lastpop) {
          window.customLocationHistory.length += 1;
        }

        window.customLocationHistory.lastpop = false;
      }
    };

    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router]);

  useEffect(() => {
    router.beforePopState(() => {
      if (window.customLocationHistory) {
        window.customLocationHistory.length -= 1;
        window.customLocationHistory.lastpop = true;
      }
 
      return true;
    });

    if (!window.customLocationHistory) {
      window.customLocationHistory = {
        length: 1,
      };
    }
  }, [router]);

  return (
    <>
      <Head>
        <title>{SiteInfoConfig.name}</title>
        <meta name="description" content={SiteInfoConfig.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, viewport-fit=cover" />
        <meta name="x5-orientation" content="portrait" />
        <link rel="icon" href={SiteInfoConfig.logo} />
        <link rel="apple-touch-icon" href={SiteInfoConfig.logo} />
        <script type="text/javascript" src="//res.wx.qq.com/open/js/jweixin-1.6.0.js" />
        <script type="text/javascript" src="https://web.cdn.openinstall.io/openinstall.js" />
        {
          false && (
            <script
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `
                  //OpenInstall初始化时将与openinstall服务器交互，应尽可能早的调用
                  /*web页面向app传递的json数据(json string/js Object)，应用被拉起或是首次安装时，通过相应的android/ios api可以获取此数据*/
                  var data = OpenInstall.parseUrlParams();///openinstall.js中提供的工具函数，解析url中的所有查询参数
    
                  if (!data.url) {
                    data.url = window.location.href;
                  }
    
                  window.$openinstall = new OpenInstall({
                    /*appKey必选参数，openinstall平台为每个应用分配的ID*/
                    appKey : "e67nxt",
                    /*自定义遮罩的html*/
                    //mask:function(){
                    // return "<div id='_shadow' style='position:fixed;" +
                    //    "left:0;top:0;background:rgba(0,255,0,0.5);filter:" +
                    //    "alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
                    //},
                    /*OpenInstall初始化完成的回调函数，可选*/
                    onready : function() {
                      /*在app已安装的情况尝试拉起app*/
                      // this.schemeWakeup();
                    }
                  }, data);
                `
              }}
            />
          )
        }
      </Head>
      <Eruda />
      <AppProvider>
        <Component {...pageProps} />
      </AppProvider>
    </>
  );
}

export default MyApp;
